/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import StoreLocator from '~/components/Pages/StoreLocator';

const StoreLocatorPage = () => {
  return (
    <Layout>
      <Metadata title="Store Locator" description="" />
      <StoreLocator />
    </Layout>
  );
};

export default StoreLocatorPage;
